<template>
    <div>
        <panel v-if="user" />
        <home v-else />
    </div>
</template>

<script>
import { supabase } from "./supabase"
import { mapState, mapActions } from 'vuex';
import Home from './views/Home.vue';
import Panel from './views/panel.vue';

export default {
  components: { Home, Panel },

    beforeMount() {
        this.stateChange();
    },
    computed:{
        ...mapState(['user'])
    },
    methods:{
        stateChange(){
            supabase.auth.onAuthStateChange((event) => {
                if (event == "SIGNED_IN") {
                    return this.initProfil();
                }
                if (event == "SIGNED_OUT") {
                    return
                }
            });
        },
        ...mapActions(['initProfil'])
    }
}
</script>
