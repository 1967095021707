<template>
    <div class="h-screen bg-no-repeat bg-cover bg-center p-10 py-20 sm:p-0 bg-auth">
        <div class="flex justify-end min-h-full">
            <div class="bg-white min-h-full p-2 w-full sm:w-1/2 flex justify-center items-center rounded-lg sm:rounded-none">
                <div class="w-64">
                    <form @submit.prevent="handleLogin">
                        <div>
                            <span class="text-sm text-gray-900">Welcome back</span>
                            <h1 class="text-2xl font-bold">Login to your account</h1>
                        </div>
                        <div v-if="message" class="my-3 text-center w-full">
                            <p>{{message}}</p>
                        </div>
                        <div v-else class="my-3">
                            <label class="block text-md mb-2" for="email">Email</label>
                            <input v-model="email" class="px-4 w-full border-2 py-2 rounded-md text-sm outline-none" type="email" name="password" placeholder="email">
                        </div>
                        <div class="">
                            <button class="flex flex-row justify-center mt-4 mb-3 w-full bg-green-500 hover:bg-green-400 text-white py-2 rounded-md transition duration-100">
                              <template v-if="loading">
                                  <svg fill='none' class="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
                                      <path clip-rule='evenodd'
                                          d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
                                          fill='currentColor' fill-rule='evenodd' />
                                  </svg>
                              </template>
                              <template v-else>
                                  Login now
                              </template>
                            </button>
                        </div>
                    </form>
                    <div class="flex  space-x-2 justify-center items-end bg-gray-700 hover:bg-gray-600 text-white py-2 rounded-md transition duration-100">
                        <img class=" h-5 cursor-pointer" src="https://i.imgur.com/arC60SB.png" alt="">
                        <button @click="googleAuth">Or Sign-in with google</button>
                    </div>
                    <div class="flex mt-4 space-x-2 justify-center items-end bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-md transition duration-100">
                        <button @click="facebookAuth" class="flex">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="w-5" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-92.4 233.5h-63.9c-50.1 0-59.8 23.8-59.8 58.8v77.1h119.6l-15.6 120.7h-104V912H539.2V602.2H434.9V481.4h104.3v-89c0-103.3 63.1-159.6 155.3-159.6c44.2 0 82.1 3.3 93.2 4.8v107.9z" fill="currentColor"/></svg>
                           <div class="ml-2">Or Sign-in with Facebook</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { supabase } from "../supabase";
export default {
    name: 'Authentification',
    data() {
        return {
            loading: false,
            email: null,
            password: "",
            message: null,
        }
    },
    beforeMount(){
        this.checkLog();
    },
    methods: {
        handleLogin() {
            if (this.email) {
              this.loading = true;
              supabase.auth.signIn({email: this.email})
              .then(() => {
                  this.loading = false;
                  this.message = "✅ Un mail de connexion a été envoyé, merci de vérifier dans votre boite mail.";
              })
            }
        },
        googleAuth() {
            supabase.auth.signIn({ provider: 'google' });
        },
        facebookAuth() {
            supabase.auth.signIn({ provider: 'facebook' });
        },
        checkLog(){
            if (this.$store.user) {
                this.$router.push({name: 'dashboard'});
            }
        }

    },
}
</script>