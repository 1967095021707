import { createStore } from 'vuex'
import { supabase } from '../supabase';

export default createStore({
    state: {
        user: null,
        profil: {},
        lists: null,
        familyMembers: [],
        presents: [],
        gifts: null,
        ownList: null,
        loading: false,
    },
    mutations: {
        //* $$$$$$$$$$ Profil CRUD $$$$$$$$$$ //
        initProfil(state) {
            state.user = supabase.auth.user();
        },
        createProfile(state, id) {
            const username = state.user.user_metadata.full_name ?? 'Anonymous';
            const update = { id, username }
            supabase.from('profiles').insert(update, {
                returning: 'minimal',
            })
            .then(() => {
                state.profil = username;
            })
        },
        updateProfil(state, profil) {
            const update = { username: profil, id: state.user.id }
            supabase.from('profiles').upsert({ id : update.id, username: update.username }, { onConflict: 'id' })
            .then(resp => {
                if (resp?.body[0]?.username) {
                    this.profile = resp.body[0].username;
                }
            })
            state.profil = profil
        },
        //* $$$$$$$$$$ Present CRUD $$$$$$$$$$ //
        createPresent(state, present) {
            supabase.from('presents').insert([{
                list_id: present.list_id,
                title: present.title,
                link: present.link,
                comment: present.comment,
                price: present.price,
            }])
            .then((resp) => {
                if (resp?.body[0]?.id) {
                    present.id = resp.body[0].id;
                    state.presents.unshift(present);
                }
            })
        },
        getPresents(state, list_id) {
            state.loading = true;
            supabase.from('presents').select().eq('list_id', list_id).order('id', {ascending: false})
            .then(data => {
                state.presents = data.body;
                state.loading = false;
                return true;
            })
        },
        updatePresent(state, present) {
            const update = {...present};
            supabase.from('presents').upsert({ 
                id : update.id,
                title: update.title,
                link: update.link,
                comment: update.comment,
                price: update.price,
                list_id: update.list_id
            }, { onConflict: 'id' })
            .then(resp => {
                if (resp.status === 201) {
                    //replace present in presents array
                    const index = state.presents.findIndex(p => p.id === update.id);
                    state.presents.splice(index, 1, update);
                }
            })
        },
        purgePresents(state) {
            state.presents = [];
        },
        //* $$$$$$$$$$ Lists CRUD $$$$$$$$$$ //
        initLists(state){
            state.loading = true;
            if (!state.lists) {
                supabase.from('lists').select().order('created_at', {ascending: false}).then(response => {
                    state.lists = response.body;
                    state.loading = false;
                });
            }
            return state.loading = false;
        },
        getLists(state){
            state.loading = true;
            supabase.from('lists').select().order('created_at', {ascending: false}).then(response => {
                state.lists = response.body;
                state.loading = false;
            });
        },
        getOwnList(state){
            supabase.from('lists').select().eq('profile_id', state.user.id).single()
            .then(response => {
                if (response.body) {
                    return state.ownList = response.body;
                }
                return
            })

        },
        createList(state, image){
            supabase.from('lists').insert([{
                image_cover_id: image,
                profile_id: state.user.id,
            }])
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    state.ownList = res.body[0];
                    state.lists.unshift(state.ownList);
                    return 
                }
            })
        },
        //* $$$$$$$$$$ Gifts CRUD $$$$$$$$$$ //
        getGifts(state) {
            supabase.from('presents').select().eq('done_by', state.user.id).order('id', {ascending: false})
            .then(data => {
                if (data.body) {
                    return state.gifts = data.body;
                }
                return state.gifts = null;
            })
        },
        
    },
    actions: {
        initProfil(context) {
            context.commit('initProfil')
            context.commit('getOwnList')
        },
        updateProfil(context, profil) {
            context.commit('updateProfil', profil)
        },
        createProfile(context, id) {
            context.commit('createProfile', id)
        },
        createPresent(context, present) {
            context.commit('createPresent', present)
        },
        getPresents(context, list_id) {
            context.commit('getPresents', list_id)
        },
        updatePresent(context, present) {
            context.commit('updatePresent', present)
        },
        purgePresents(context) {
            context.commit('purgePresents')
        },
        initLists(context){
            context.commit('initLists')
        },
        getLists(context){
            context.commit('getLists')
        },
        createList(context, image){
            context.commit('createList', image)
        },
        getGifts(context) {
            context.commit('getGifts')
        }
    },
    modules: {
    }
})
