import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/', component: () => import('../App.vue') , name: 'login' },
  { path: '/dashboard', component: () => import('../views/Dashboard.vue'), name: 'dashboard' },
  { path: '/lists', component: () => import('../views/Lists.vue'), name: 'lists' },
  { path: '/gifts', component: () => import('../views/Gifts.vue'), name: 'gifts' },
  { path: '/profil', component: () => import('../views/Profil.vue'), name: 'profil' },
  { path: '/toEdit/:id', component: () => import('../views/EditList.vue'), name: 'editList' },
  { path: '/toSee/:id', component: () => import('../views/ViewList.vue'), name: 'viewList' },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
