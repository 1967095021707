<template>
    <router-view />
</template>

<script>
export default {
    name: 'Panel',
    mounted() {
        this.$router.push({name: 'dashboard'});
    },
}
</script>